<template>
     <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 auth-con">
                <div class="logo-site">  <img src="/theme/img/logo-dark.svg"></div>
               <div class="authbox">
                   <h3 class="text-b">Create New Password</h3>
                   <Form  id="newpassword" method="post" @submit="newPasspHandle" v-if="!newPwd">
                       <div class="mb-3">
                            <label for="usernewpass" class="form-label">Create new password</label>
                            <Field ref="usernewpass"  type="password" class="form-control" id="usernewpass"  name="usernewpass" placeholder="min length 6" :rules="validatePwd">
                            </Field>
                            <ErrorMessage name="usernewpass" />
                        </div>  
                        <div class="mb-3">
                            <p v-html="model.status"></p>
                        </div>
                        <div class="mb-3">
                            <button class="btn" type="submit">Submit</button>
                        </div>
                        <div class="mb-3 text-justify">
                            <a class="auth-nav" href="/auth/login">Login</a> 
                        </div>
                   </Form >
                   <div class=""  v-else >
                       <p v-html="model.status"></p>
                       <div class="mb-3">
                            <a class="btn" href="/auth/login">Go to Login</a>
                        </div>
                   </div>
               </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field ,ErrorMessage} from 'vee-validate';
import axios from 'axios'
export default {
    components: { Form, Field, ErrorMessage},
    data () {
        return {
            newPwd:false,
            model: {
                usernewpass: '',
                status:''
            }
        }
    },
    mounted() {
        if(this.$refs.usernewpass) this.$refs.usernewpass.$el.focus()
    },
    methods: {
        newPasspHandle(values){
            var self=this;
            axios.post('/api/auth/resetpassword', {
                newpassword:values.usernewpass,
                key:self.$route.params.pwdrecoverykey
            }).then( async response => {
                self.newPwd = true
                self.model.status = `<p>${response.data.message}</p>`;
           
        }).catch(error=>{
            self.model.status = error.response.data.message
        })},
         validatePwd(value){
            if (!value) {   return 'Password is required';}
            if (value.length<6) {   return 'Password min 6 chars required';}
            if (value.length>42) {   return 'Password maximum length 42 chars';}
            var regex =  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,34}$/;
            if (!regex.test(value)) {   return 'Password min length 6 has at least one number and one special character (!@#$%^&*)';}
            return true;
        } 
    }
}
</script>